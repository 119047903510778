import { LoyaltyTransactionItem } from '@bofrak-backend/shared';

export class LoyaltyPointsCalculator {
  static getEarnedPointsByMoney(
    transaction: LoyaltyTransactionItem[],
    points_per_threshold: number,
    threshold_amount: number,
  ): number {
    const eligible_for_points = transaction.filter(
      (item) => item.eligible_for_points,
    );
    const total_amount = eligible_for_points.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0,
    );

    return Number(
      ((total_amount * points_per_threshold) / threshold_amount).toFixed(1),
    );
  }

  static getEarnedPointsByVisits(
    visits: number,
    points_per_threshold: number,
    threshold_amount: number,
  ): number {
    return Number(
      ((visits * points_per_threshold) / threshold_amount).toFixed(1),
    );
  }

  static getEarnedPointsByPercentage(
    total_amount: number,
    points_per_threshold: number,
  ): number {
    // For percentage-based programs, assume threshold_amount is always 100
    return Number(((total_amount * points_per_threshold) / 100).toFixed(1));
  }
}
