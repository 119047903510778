import {
  defaultImage,
  isProductForSale,
  StoreProduct,
} from '@bofrak-backend/shared';
import { Box, BoxProps, Image, Stack, Text, VStack } from '@chakra-ui/react';
import { uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useCart } from '../../hooks/use-cart';
import { productsAtom } from '../../recoil/atoms';
import { ProductCheckboxCard } from './product-check-box';

interface SelectChildProps {
  product: StoreProduct;
}

// If you still need a "SelectChild"-like container to render multiple variants/fractions:
export const SelectChild = ({ product }: SelectChildProps) => {
  const { addItem, cartData, deleteItem, updateItemQuantity } = useCart();
  const products = useRecoilValue(productsAtom);
  const [storeProducts, setStoreProducts] = useState<StoreProduct[]>([]);

  // Gather fraction IDs (including the parent)
  const store_product_fractions: string[] = uniq([
    product.id,
    ...product.store_product_fractions.map((fraction) => fraction.id),
  ]);

  useEffect(() => {
    if (products) {
      setStoreProducts(
        products.filter((p) => store_product_fractions.includes(p.id)),
      );
    }
  }, [products]);

  return (
    <Box width="full" height="full" as="section" bg="bg.surface" py="5">
      <Card>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '4', md: '10' }}>
          <VStack>
            <Text
              width="full"
              size="lg"
              textAlign="center"
              fontWeight="bold"
              letterSpacing="tight">
              {product.name}
            </Text>
            <Image
              src={product.image}
              alt={product.name}
              maxW="150px"
              draggable="false"
              fallback={<Image maxW="150px" src={defaultImage} />}
            />
            <Text
              size="lg"
              fontWeight="medium"
              textAlign="center"
              letterSpacing="tight">
              {product.inventory.toFixed(2)} in Stock
            </Text>
          </VStack>

          <CardContent>
            <VStack maxW="lg">
              {storeProducts.map((option) => {
                const cartItem = cartData.find(
                  (item) => item.item_id === option.id,
                );
                const quantity = cartItem?.quantity ?? 0;
                const lineQuantity = cartItem?.line_quantity ?? 0;

                // Compute if it's actually for sale & reason
                const { is_available_for_sale, reason } = isProductForSale({
                  fraction: option.fraction,
                  is_sold_online: option.is_sold_online,
                  is_available_for_sale: option.is_available_for_sale,
                  inventory: option.inventory,
                  is_available_in_store: option.is_available_in_store,
                });

                return (
                  <ProductCheckboxCard
                    key={option.id}
                    value={option.id}
                    isInCart={!!cartItem}
                    cartId={cartItem?.id ?? ''}
                    quantity={quantity}
                    lineQuantity={lineQuantity}
                    product={option}
                    updateItemQuantity={updateItemQuantity}
                    addItem={addItem}
                    deleteItem={deleteItem}
                    is_available_for_sale={is_available_for_sale}
                    reason={reason}
                  />
                );
              })}
            </VStack>
          </CardContent>
        </Stack>
      </Card>
    </Box>
  );
};

// Helper "Card" wrappers from your snippet
const CardContent = (props: BoxProps) => <Box width="full" {...props} />;
const Card = (props: BoxProps) => (
  <Box
    mx="auto"
    height="full"
    p={{ base: '6', md: '8' }}
    rounded={{ sm: 'lg' }}
    {...props}
  />
);
