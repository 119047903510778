import { Customer } from '@bofrak-backend/shared';
import {
  Button,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  StackDivider,
  useToast,
} from '@chakra-ui/react';
import { useNetworkState } from '@uidotdev/usehooks';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ulid } from 'ulidx';
import { shopAndSmileAdapter } from '../../api/backend';
import { db } from '../../api/local'; // Ensure you have a db with offlineCustomers
import {
  merchantAtom,
  offlineCustomersAtom,
  workerActionsAtom,
} from '../../recoil/atoms';
import { WebWorkerActions } from '../../utils/types';

interface CreateCustomerOverlayProps {
  isOpen: boolean;
  onClose: () => void;
  placement?: 'top' | 'right' | 'bottom' | 'left';
}

interface CreateCustomerFormValues {
  name: string;
  email?: string;
  phone_number: string;
  address?: string;
  city?: string;
  region?: string;
  postal_code?: string;
  country_code?: string;
  customer_code?: string;
  note?: string;
}

const CreateCustomerOverlay = ({
  isOpen,
  onClose,
  placement = 'top',
}: CreateCustomerOverlayProps) => {
  const toast = useToast();
  const setOfflineCustomers = useSetRecoilState(offlineCustomersAtom);
  const setWorkerAction = useSetRecoilState(workerActionsAtom);
  const merchant = useRecoilValue(merchantAtom); // Access merchant_id from Recoil
  const { online } = useNetworkState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateCustomerFormValues>();

  // Mutation for creating a customer online
  const mutation = useMutation(
    (
      createCustomerDto: CreateCustomerFormValues & {
        merchant_id: string;
        pin: string;
      },
    ) => shopAndSmileAdapter.createCustomer(createCustomerDto),
    {
      onSuccess: (data: Customer) => {
        toast({
          title: 'Customer Created',
          description: `Customer ${data.name} created successfully!`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        // Trigger your web worker to sync the latest customers down
        setWorkerAction([WebWorkerActions.SYNC_CUSTOMERS]);

        onClose();
      },
      onError: (error: Error) => {
        toast({
          title: 'Error',
          description: error?.message || 'Failed to create customer.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );

  /**
   * onSubmit handler for creating a customer.
   * If the user is online, call the backend. If offline, store in offlineCustomers.
   */
  const onSubmit = async (data: CreateCustomerFormValues) => {
    if (!merchant) {
      toast({
        title: 'Error',
        description: 'Merchant information not found',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // If user is online, call backend directly
    if (online) {
      mutation.mutate({ ...data, merchant_id: merchant.id, pin: '1234' });
    } else {
      // Offline scenario: Add the customer to the offlineCustomers Dexie table
      try {
        // Generate a random ID (or handle ID logic however you prefer)
        const newCustomerId = ulid();

        const offlineCustomer: Customer = {
          // Provide required customer properties here
          id: newCustomerId,
          merchant_id: merchant.id,
          name: data.name,
          phone_number: data.phone_number,
          email: data.email || '',
          address: data.address || '',
          city: data.city || '',
          region: data.region || '',
          postal_code: data.postal_code || '',
          country_code: data.country_code || '',
          pin: '1234', // Default pin for offline customers
          customer_code: data.customer_code || undefined,
          note: data.note || undefined,
          created_at: new Date().toISOString(),
          total_points: 0,
          total_spent: 0,
          total_visits: 1,
          updated_at: new Date().toISOString(),
        };

        await db.offlineCustomers.put(offlineCustomer);
        setOfflineCustomers((prev) => [...prev, offlineCustomer]);

        toast({
          title: 'Offline Customer Created',
          description: `Customer "${offlineCustomer.name}" saved offline.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        onClose();
      } catch (error) {
        toast({
          title: 'Error Saving Customer Offline',
          description:
            (error as Error)?.message ||
            'An unexpected error occurred while saving the customer offline.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Drawer size="xl" placement={placement} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody>
          <Container py={{ base: '4', md: '8' }}>
            <Stack spacing="5" divider={<StackDivider />}>
              <Stack
                direction="column"
                spacing={{ base: '5', lg: '8' }}
                justify="space-around">
                <Heading textAlign={'center'} as="h2" size="lg">
                  Create Customer
                </Heading>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing="4" px={'20'} width={'auto'}>
                    <FormControl isInvalid={!!errors.name}>
                      <FormLabel>Name</FormLabel>
                      <Input
                        {...register('name', {
                          required: 'Name is required',
                          maxLength: {
                            value: 64,
                            message: 'Name must be at most 64 characters',
                          },
                        })}
                      />
                    </FormControl>
                    <FormControl isInvalid={!!errors.email}>
                      <FormLabel>Email</FormLabel>
                      <Input
                        type="email"
                        {...register('email', {
                          maxLength: {
                            value: 100,
                            message: 'Email must be at most 100 characters',
                          },
                        })}
                      />
                    </FormControl>
                    <FormControl isInvalid={!!errors.phone_number}>
                      <FormLabel>Phone Number</FormLabel>
                      <Input
                        {...register('phone_number', {
                          // required: 'Phone number is required', // optional
                          maxLength: {
                            value: 15,
                            message:
                              'Phone number must be at most 15 characters',
                          },
                        })}
                      />
                    </FormControl>
                    <FormControl isInvalid={!!errors.address}>
                      <FormLabel>Address</FormLabel>
                      <Input
                        {...register('address', {
                          maxLength: {
                            value: 192,
                            message: 'Address must be at most 192 characters',
                          },
                        })}
                      />
                    </FormControl>
                    <FormControl isInvalid={!!errors.city}>
                      <FormLabel>City</FormLabel>
                      <Input
                        {...register('city', {
                          maxLength: {
                            value: 64,
                            message: 'City must be at most 64 characters',
                          },
                        })}
                      />
                    </FormControl>
                    <FormControl isInvalid={!!errors.region}>
                      <FormLabel>Region</FormLabel>
                      <Input
                        {...register('region', {
                          maxLength: {
                            value: 64,
                            message: 'Region must be at most 64 characters',
                          },
                        })}
                      />
                    </FormControl>
                    <FormControl isInvalid={!!errors.postal_code}>
                      <FormLabel>Postal Code</FormLabel>
                      <Input
                        {...register('postal_code', {
                          maxLength: {
                            value: 20,
                            message:
                              'Postal code must be at most 20 characters',
                          },
                        })}
                      />
                    </FormControl>
                    <FormControl isInvalid={!!errors.country_code}>
                      <FormLabel>Country Code</FormLabel>
                      <Input
                        {...register('country_code', {
                          maxLength: {
                            value: 2,
                            message: 'Country code must be 2 characters',
                          },
                          pattern: {
                            value: /^[A-Z]{2}$/,
                            message:
                              'Country code must be in ISO 3166-1-alpha-2 format',
                          },
                        })}
                      />
                    </FormControl>
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isLoading={mutation.isLoading}>
                      Save
                    </Button>
                  </Stack>
                </form>
              </Stack>
            </Stack>
          </Container>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default CreateCustomerOverlay;
