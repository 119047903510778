// recoil/selectors.ts

import { selector } from 'recoil';
import {
  cartItemsAtom,
  allowDiscountsState,
  activeLoyaltyProgramsAtom,
} from './atoms';
import { CustomerCart, CartItemInterface } from '../utils/types';
import {
  adjustPayments,
  applyDiscountsToCartItem,
  applyTaxesToCartItem,
  calculateEarnedPoints,
} from '../utils/calculations';
import { PaymentMethodType } from '@bofrak-backend/shared';

export const computedCartsSelector = selector<CustomerCart[]>({
  key: 'computedCartsSelector',
  get: ({ get }) => {
    const carts = get(cartItemsAtom);
    const allowDiscounts = get(allowDiscountsState);
    const loyaltyPrograms = get(activeLoyaltyProgramsAtom);

    return carts.map((cart) => {
      if (cart.cart_items.length === 0) {
        // Empty cart – just return it as is with zeros
        return {
          ...cart,
          total_money: 0,
          gross_total_money: 0,
          total_discounts: 0,
          total_added_taxes: 0,
          total_included_taxes: 0,
          total_taxes: [],
          points_earned: 0,
          points_balance: cart.points_balance || 0,
          points_deducted: 0,
        };
      }

      const cartDiscounts = cart.additional_discounts || [];

      let totalMoney = 0;
      let grossTotalMoney = 0;
      let totalDiscounts = 0;
      let totalIncludedTaxes = 0;
      let totalAddedTaxes = 0;

      // Process each item with discounts and taxes
      const computedItems: CartItemInterface[] = cart.cart_items.map((item) => {
        // Apply discounts
        const discountedItem = applyDiscountsToCartItem(
          item,
          cartDiscounts,
          allowDiscounts,
        );

        // Apply taxes
        const taxedItem = applyTaxesToCartItem(discountedItem);

        const lineOriginalPrice =
          taxedItem.original_price * taxedItem.line_quantity;
        const linePriceAfterDiscount =
          lineOriginalPrice - taxedItem.total_discount;

        const lineDiscount = lineOriginalPrice - linePriceAfterDiscount;

        const lineIncludedTaxes = taxedItem.total_line_included_taxes || 0;
        const lineAddedTaxes = taxedItem.total_line_added_taxes || 0;

        const lineGrossTotal = linePriceAfterDiscount + lineAddedTaxes;

        totalMoney += linePriceAfterDiscount;
        totalDiscounts += lineDiscount;
        totalIncludedTaxes += lineIncludedTaxes;
        totalAddedTaxes += lineAddedTaxes;
        grossTotalMoney += lineGrossTotal;

        return {
          ...taxedItem,
          line_original_price: lineOriginalPrice,
          total_discount: lineDiscount,
          total_money: linePriceAfterDiscount,
          gross_total_money: lineGrossTotal,
        };
      });

      // Adjust payments to avoid overpayment and calculate change
      const { updatedPayments, change } = adjustPayments(
        cart.payments || [],
        grossTotalMoney,
      );

      // Flatten line taxes and discounts for aggregated fields
      const allLineTaxes = computedItems.map((item) => item.line_taxes).flat();
      const allLineDiscounts = computedItems
        .map((item) => item.line_discounts ?? [])
        .flat();

      // Calculate loyalty points earned
      const points_earned = calculateEarnedPoints(
        { ...cart, gross_total_money: grossTotalMoney },
        loyaltyPrograms,
      );

      const pointsFromPayments = updatedPayments
        .filter((x) => x.payment_type === PaymentMethodType.LOYALTY_POINTS)
        .reduce((acc, x) => acc + x.money_amount, 0);

      const currentCustomerPoints = cart.customer?.total_points || 0;

      const points_deducted = Math.max(
        0,
        Math.min(pointsFromPayments, currentCustomerPoints),
      );

      const points_balance = Math.max(
        currentCustomerPoints - points_deducted,
        0,
      );

 

      return {
        ...cart,
        cart_items: computedItems,
        total_taxes: allLineTaxes,
        discounts: allLineDiscounts,
        total_money: totalMoney,
        gross_total_money: grossTotalMoney,
        total_included_taxes: totalIncludedTaxes,
        total_added_taxes: totalAddedTaxes,
        total_discounts: totalDiscounts,
        points_earned,
        points_deducted,
        points_balance,
        payments: updatedPayments,
        change,
      };
    });
  },
});
