import { formatCurrency, Receipt, Store } from '@bofrak-backend/shared';
import {
  addDoubleHorizontalLine,
  addHorizontalLine,
  addLineBreak,
  addQRCode,
  alignCenter,
  alignLeft,
  alignRight,
  bigText,
  boldText,
  cutPaper,
  mediumText,
  underlineText,
} from '@bofrak-backend/shared-ui';

/* eslint-disable @typescript-eslint/no-explicit-any */
export function unStringfy(str: any) {
  try {
    return JSON.parse(str);
  } catch (e) {
    console.log('🚀 ~ file: functions.ts:7 ~ unStringfy ~ e:', e);

    return str;
  }
}

/**
 * Formats a receipt object into a Quick Printer compatible string.
 *
 * @param receipt - The receipt data containing items and total.
 * @param store - The store information.
 * @returns A formatted string with Quick Printer commands.
 */
export function receiptToQuickPrinterFormat(
  receipt: Receipt,
  store: Store,
): string {
  // Start constructing the receipt content
  let receiptContent = `
    ${alignCenter(bigText(store.name + 'TEST TEST'))}
    ${alignCenter(mediumText(store.address || 'No Store address provided'))}
    ${alignCenter(mediumText(store.city || 'No Store city provided'))}
    ${addLineBreak()}
    ${boldText('ITEMS')}
    ${addHorizontalLine()}
  `;

  // Iterate over each line item and append to the receipt content
  receipt.line_items.forEach((item) => {
    // Format the item line as: quantity x name   price
    const itemName = `${item.line_quantity} x ${item.item_name}`;
    const itemPrice = formatCurrency(item.gross_total_money, true); // Assuming price is per unit

    receiptContent += `${alignLeft(itemName)} ${alignRight(itemPrice)}\n`;
  });

  // Append the total section
  receiptContent += `
    ${addDoubleHorizontalLine()}
    ${boldText('TOTAL')}
    ${alignRight(formatCurrency(receipt.total_money, true))}
    ${addLineBreak()}
    ${underlineText('Thank you for your purchase!')}
    ${underlineText('www.shopnsmile.org')}
    ${addQRCode('https://shopnsmile.org')}
    ${cutPaper()}
  `;

  return receiptContent;
}
