// receiptUtils.ts

/**
 * Utility functions to format receipt content with Quick Printer commands.
 */

/**
 * Adds a line break.
 *
 * @returns A string containing the line break command.
 */
export const addLineBreak = (): string => '<BR>';

/**
 * Formats text to small size.
 *
 * @param text - The text to format.
 * @returns The formatted text with small size.
 */
export const smallText = (text: string): string =>
  `<SMALL>${text}${addLineBreak()}`;

/**
 * Formats text to medium size.
 *
 * @param text - The text to format.
 * @returns The formatted text with medium size.
 */
export const mediumText = (text: string): string =>
  `<MEDIUM1>${text}${addLineBreak()}`;

/**
 * Formats text to big size.
 *
 * @param text - The text to format.
 * @returns The formatted text with big size.
 */
export const bigText = (text: string): string =>
  `<BIG>${text}${addLineBreak()}`;

/**
 * Makes text bold.
 *
 * @param text - The text to format.
 * @returns The formatted bold text.
 */
export const boldText = (text: string): string =>
  `<BOLD>${text}${addLineBreak()}`;

/**
 * Aligns text to the left.
 *
 * @param text - The text to align.
 * @returns The left-aligned text.
 */
export const alignLeft = (text: string): string =>
  `<LEFT>${text}${addLineBreak()}`;

/**
 * Aligns text to the center.
 *
 * @param text - The text to align.
 * @returns The center-aligned text.
 */
export const alignCenter = (text: string): string =>
  `<CENTER>${text}${addLineBreak()}`;

/**
 * Aligns text to the right.
 *
 * @param text - The text to align.
 * @returns The right-aligned text.
 */
export const alignRight = (text: string): string =>
  `<RIGHT>${text}${addLineBreak()}`;

/**
 * Underlines text.
 *
 * @param text - The text to underline.
 * @returns The underlined text.
 */
export const underlineText = (text: string): string =>
  `<UNDERLINE>${text}${addLineBreak()}`;

/**
 * Adds a horizontal line.
 *
 * @returns A string containing the horizontal line command.
 */
export const addHorizontalLine = (): string => `<LINE>${addLineBreak()}`;

/**
 * Adds a double horizontal line.
 *
 * @returns A string containing the double horizontal line command.
 */
export const addDoubleHorizontalLine = (): string => `<DLINE>${addLineBreak()}`;

/**
 * Adds a QR code.
 *
 * @param data - The data to encode in the QR code.
 * @returns A string containing the QR code command.
 */
export const addQRCode = (data: string): string =>
  `<QR>${data}${addLineBreak()}`;

/**
 * Cuts the paper.
 *
 * @returns A string containing the cut paper command.
 */
export const cutPaper = (): string => `<CUT>`;
