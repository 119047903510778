// SelectPosDevice.tsx
import { PosDevice, UpdatePosDevice } from '@bofrak-backend/shared';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Heading,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { shopAndSmileAdapter } from '../../api/backend';
import { db } from '../../api/local';
import {
  merchantAtom,
  posDeviceAtom,
  shouldSignOutAtom,
  storeAtom,
  userAtom,
} from '../../recoil/atoms';
import { colors, envVars } from '../../utils/constants';
import { RadioCard, RadioCardGroup } from '../radio-card-group';

export const SelectPosDevice = () => {
  const [shouldSignOut, setShouldSignOut] = useRecoilState(shouldSignOutAtom);
  const merchant = useRecoilValue(merchantAtom);
  const store = useRecoilValue(storeAtom);
  const user = useRecoilValue(userAtom);
  const setSelectedPosDevice = useSetRecoilState(posDeviceAtom);

  const toast = useToast();

  // Fetch POS devices
  const { data, isLoading } = useQuery({
    enabled: !!merchant && !!store,
    keepPreviousData: true,
    queryFn: async () => {
      if (!merchant || !store) {
        throw new Error('No merchant or store found');
      }

      return shopAndSmileAdapter.getPOSDevices({
        merchant_id: merchant.id,
        store_id: store.id,
        limit: 100,
      });
    },
    onError: (error: Error) => {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    },
  });

  // Mutation for updating a POS device
  const { mutateAsync } = useMutation(
    (data: UpdatePosDevice) => shopAndSmileAdapter.updatePOSDevice(data),
    {
      onSuccess: async (data: PosDevice) => {
        setSelectedPosDevice(data);
        toast({
          title: 'POS Device Updated',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });

        // Store the updated POS device in IndexedDB
        await savePosDeviceToLocalStorage(data);
      },
      onError: (error: Error) => {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      },
    },
  );

  async function savePosDeviceToLocalStorage(posDevice: PosDevice) {
    await db.posDevices.put(posDevice);
  }

  useEffect(() => {
    if (data && data.pos_devices.length && user) {
      const selectedPosDevice = data.pos_devices.find(
        (device) => device.current_user_id === user.id,
      );

      if (selectedPosDevice) {
        setSelectedPosDevice(selectedPosDevice);
        savePosDeviceToLocalStorage(selectedPosDevice);
      }
    }
  }, [data, user]);

  if (!merchant || !store || !user) {
    return (
      <Box as="section">
        <Container py={{ base: '16', md: '24' }}>
          <Stack spacing={{ base: '12', md: '16' }} align="center">
            <Text textStyle="h3">No Merchant, Store or User found</Text>
            <Button
              isLoading={shouldSignOut}
              onClick={() => {
                setShouldSignOut(true);
              }}
              borderRadius={'full'}
              bg={colors.primary}>
              Home
            </Button>
          </Stack>
        </Container>
      </Box>
    );
  }

  return isLoading || !data ? (
    <Box as="section">
      <Container py={{ base: '16', md: '24' }}>
        <Stack spacing={{ base: '12', md: '16' }} align="center">
          <CircularProgress isIndeterminate color="green.300" />
          <Text textStyle="h3">Loading POS Devices</Text>
        </Stack>
      </Container>
    </Box>
  ) : (
    <Box as="section">
      <Container width={'md'} py={{ base: '16', md: '24' }}>
        <VStack spacing="3" align="center">
          <Heading fontSize={'30'} textAlign={'center'} fontWeight="semibold">
            Select a Point Of Sale Device
          </Heading>
          {!data || !data.pos_devices.length ? (
            <Button
              bg={colors.blue}
              borderRadius={'full'}
              color="white"
              onClick={() => {
                window.location.href = `https://settings.${envVars.STAGE}.shopnsmile.org/pos-devices`;
              }}>
              Create Device
            </Button>
          ) : null}
        </VStack>
        <RadioCardGroup
          onChange={async (value: string) => {
            if (!merchant || !user) {
              toast({
                title: 'No Merchant or User Found',
                status: 'error',
                duration: 9000,
                isClosable: true,
              });
              return;
            }

            if (!data) {
              toast({
                title: 'No POS Devices',
                status: 'error',
                duration: 9000,
                isClosable: true,
              });
              return;
            }

            const selectedPosDevice = data.pos_devices.find(
              (device) => device.id.toLowerCase() === value.toLowerCase(),
            );

            if (selectedPosDevice) {
              await mutateAsync({
                id: selectedPosDevice.id,
                merchant_id: merchant.id,
                activated: true,
                current_user_id: user.id,
              });
            } else {
              toast({
                title: 'No POS Device Found',
                status: 'error',
                duration: 9000,
                isClosable: true,
              });
            }
          }}
          spacing="3">
          {data.pos_devices
            .filter((x) => !x.activated)
            .map((option) => (
              <RadioCard key={option.id} value={option.id}>
                <Text color="fg.emphasized" fontWeight="medium" fontSize="sm">
                  {option.name}
                </Text>
                <Text color="fg.muted" textStyle="sm">
                  <strong>PREFIX:</strong> {option.prefix.toUpperCase()}
                </Text>
              </RadioCard>
            ))}
        </RadioCardGroup>
      </Container>
    </Box>
  );
};
