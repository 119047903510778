// src/hooks/useQuickPrinter.ts

import { useCallback } from 'react';

/**
 * Type definition for Quick Printer options.
 */
interface QuickPrinterOptions {
  /**
   * Optional alias of the printer.
   * @example "alias='your_printer_alias'"
   */
  printerAlias?: string;

  /**
   * Optional group of printers.
   * @example "group='your_printer_group'"
   */
  printerGroup?: string;

  /**
   * Number of times to repeat the print.
   * @example "repeat='3'"
   */
  repeat?: number;

  /**
   * Additional configurations for premium features.
   */
  configErrorDialog?: boolean;
  configColorBackgroundDialog?: string; // Hex color code
  configColorTextDialog?: string; // Hex color code
  configTextDialog?: string;
}

/**
 * Custom React hook to print receipts using Quick Printer with intent:// deep linking.
 *
 * @returns An object containing the `printReceipt` function.
 */
export const useQuickPrinter = () => {
  /**
   * Function to initiate printing using deep linking.
   *
   * @param receiptData - The formatted receipt string with Quick Printer commands.
   * @param options - Optional configurations for printing.
   */
  const printReceipt = useCallback(
    (receiptData: string, options?: QuickPrinterOptions) => {
      if (!receiptData || typeof receiptData !== 'string') {
        console.error('Receipt data must be a non-empty string.');
        return;
      }

      // Construct the printer command based on options
      let printerCommand = '';

      if (options?.printerAlias) {
        printerCommand += `<PRINTER alias='${options.printerAlias}'>`;
      }

      if (options?.printerGroup) {
        printerCommand += `<PRINTER group='${options.printerGroup}'>`;
      }

      if (options?.repeat && options.repeat > 1) {
        printerCommand += `<PRINTER repeat='${options.repeat}'>`;
      }

      // Append the actual receipt data
      printerCommand += receiptData;

      // Close the PRINTER tag if opened
      if (
        options?.printerAlias ||
        options?.printerGroup ||
        (options?.repeat && options.repeat > 1)
      ) {
        printerCommand += `</PRINTER>`;
      }

      // Append premium feature configurations if provided
      if (options?.configErrorDialog !== undefined) {
        printerCommand += `<PRINTER config_error_dialog='${options.configErrorDialog}' />`;
      }
      if (options?.configColorBackgroundDialog) {
        printerCommand += `<PRINTER config_color_background_dialog='${options.configColorBackgroundDialog}' />`;
      }
      if (options?.configColorTextDialog) {
        printerCommand += `<PRINTER config_color_text_dialog='${options.configColorTextDialog}' />`;
      }
      if (options?.configTextDialog) {
        printerCommand += `<PRINTER config_text_dialog='${options.configTextDialog}' />`;
      }

      // Encode the printer command
      const encodedData = encodeURIComponent(printerCommand);

      // Detect if the user is on Android and using Chrome
      const isAndroid = /Android/i.test(navigator.userAgent);
      const isChrome =
        /Chrome/i.test(navigator.userAgent) &&
        !/Edge|OPR|Brave|Vivaldi/i.test(navigator.userAgent);

      // Construct the Quick Printer URL based on the browser
      let quickPrinterURL = '';

      if (isAndroid && isChrome) {
        // Use intent:// scheme for Chrome on Android
        quickPrinterURL = `intent://${encodedData}#Intent;scheme=quickprinter;package=pe.diegoveloper.printerserverapp;end;`;
      } else {
        // Fallback to custom scheme
        quickPrinterURL = `quickprinter://${encodedData}`;
      }

      // Create an iframe to attempt to open the Quick Printer app
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = quickPrinterURL;
      document.body.appendChild(iframe);
    },
    [],
  );

  return { printReceipt };
};
