import {
  Discount,
  DiscountCalculator,
  DiscountType,
  ReceiptDiscount,
  TaxCalculator,
  Tax,
  TaxType,
  LoyaltyType,
  LoyaltyProgram,
  LoyaltyTransactionItem,
  LoyaltyPointsCalculator,
  ReceiptPayment,
} from '@bofrak-backend/shared';
import { CartItemInterface, CustomerCart } from '../utils/types';

export function adjustPayments(
  payments: ReceiptPayment[],
  grossTotal: number,
): { updatedPayments: ReceiptPayment[]; change: number } {
  if (!payments || payments.length === 0) {
    return { updatedPayments: [], change: 0 };
  }

  const totalPayments = payments.reduce((acc, p) => acc + p.money_amount, 0);

  if (totalPayments <= grossTotal) {
    return {
      updatedPayments: payments.filter((x) => x.money_amount > 0),
      change: 0,
    };
  }

  const overpaid = totalPayments - grossTotal;
  const updatedPayments = [...payments];
  const lastIndex = updatedPayments.length - 1;
  const lastPayment = updatedPayments[lastIndex];
  const adjustedAmount = lastPayment.money_amount - overpaid;

  if (adjustedAmount <= 0) {
    // Remove this payment if it becomes zero or negative
    updatedPayments.pop();
  } else {
    updatedPayments[lastIndex] = {
      ...lastPayment,
      money_amount: adjustedAmount,
    };
  }

  const change = overpaid;
  return {
    updatedPayments: updatedPayments.filter((x) => x.money_amount > 0),
    change,
  };
}

export function calculateEarnedPoints(
  cart: CustomerCart,
  activeLoyaltyPrograms: LoyaltyProgram[],
): number {
  if (!cart.customer || activeLoyaltyPrograms.length === 0) return 0;

  let totalEarnedPoints = 0;

  for (const program of activeLoyaltyPrograms) {
    switch (program.loyalty_type) {
      case LoyaltyType.MONEY_BASED: {
        const transactions: LoyaltyTransactionItem[] = cart.cart_items.map(
          (x) => {
            return {
              eligible_for_points: true,
              price: x.original_price * x.line_quantity - x.total_discount, // Price after discount
              product_id: x.item_id,
              product_name: x.item_name,
              quantity: x.quantity,
            };
          },
        );
        totalEarnedPoints += LoyaltyPointsCalculator.getEarnedPointsByMoney(
          transactions,
          program.points_per_threshold,
          program.threshold_amount,
        );
        break;
      }
      case LoyaltyType.VISIT_BASED: {
        const visits = cart.customer?.total_visits ?? 0;
        totalEarnedPoints += LoyaltyPointsCalculator.getEarnedPointsByVisits(
          visits,
          program.points_per_threshold,
          program.threshold_amount,
        );
        break;
      }
      case LoyaltyType.PERCENTAGE_BASED: {
        const totalAmount = cart.gross_total_money;
        totalEarnedPoints +=
          LoyaltyPointsCalculator.getEarnedPointsByPercentage(
            totalAmount,
            program.points_per_threshold,
          );
        break;
      }
      default:
        break;
    }
  }

  return totalEarnedPoints;
}

export function applyTaxesToCartItem(
  item: CartItemInterface,
): CartItemInterface {
  const priceAfterDiscount =
    item.original_price * item.line_quantity - item.total_discount;

  const allTaxes = [
    ...(item.added_product_taxes || []),
    ...(item.line_taxes || []),
  ];

  // Remove duplicate taxes by ID
  const uniqueTaxes = allTaxes.reduce((acc: Tax[], t) => {
    if (!acc.find((x) => x.id === t.id)) acc.push(t);
    return acc;
  }, []);

  const includedTaxes = uniqueTaxes.filter(
    (tax) => tax.type === TaxType.INCLUDED,
  );
  const addedTaxes = uniqueTaxes.filter((tax) => tax.type === TaxType.ADDED);

  let totalIncludedTaxes = 0;
  const includedLineTaxes = includedTaxes.map((tax) => {
    const { taxValue } = TaxCalculator.calculateIncludedTax(
      priceAfterDiscount,
      tax.rate,
    );
    totalIncludedTaxes += taxValue;
    return { ...tax, money_amount: taxValue };
  });

  const priceExcludingIncludedTaxes = priceAfterDiscount - totalIncludedTaxes;

  let totalAddedTaxes = 0;
  const addedLineTaxes = addedTaxes.map((tax) => {
    const taxValue = TaxCalculator.calculateAddedTax(
      priceExcludingIncludedTaxes,
      tax.rate,
    );
    totalAddedTaxes += taxValue;
    return { ...tax, money_amount: taxValue };
  });

  const line_taxes = [...includedLineTaxes, ...addedLineTaxes];

  return {
    ...item,
    line_taxes,
    total_line_included_taxes: totalIncludedTaxes,
    total_line_added_taxes: totalAddedTaxes,
  };
}

export function applyDiscountsToCartItem(
  item: CartItemInterface,
  cartDiscounts: Discount[],
  allowDiscounts: boolean,
): CartItemInterface {
  const lineOriginalPrice = item.original_price * item.line_quantity;

  // Combine product-level and cart-level discounts if allowed
  const allDiscounts = allowDiscounts
    ? [...(item.product_discounts || []), ...cartDiscounts]
    : [];

  // Remove duplicate discounts by ID
  const uniqueDiscounts = allDiscounts.reduce((acc: Discount[], d) => {
    if (!acc.find((x) => x.id === d.id)) acc.push(d);
    return acc;
  }, []);

  let totalDiscount = 0;
  const line_discounts: ReceiptDiscount[] = [];

  for (const discount of uniqueDiscounts) {
    let discountResult;
    if (
      discount.type === DiscountType.FIXED_AMOUNT &&
      discount.discount_amount
    ) {
      // Fixed amount discount
      discountResult = DiscountCalculator.calculateFixedDiscount(
        lineOriginalPrice,
        discount.discount_amount,
      );
    } else if (
      discount.type === DiscountType.FIXED_PERCENT &&
      discount.discount_percent
    ) {
      // Percentage discount
      discountResult = DiscountCalculator.calculatePercentageDiscount(
        lineOriginalPrice,
        discount.discount_percent,
      );
    }

    if (
      discountResult &&
      discountResult.discount_value &&
      discountResult.discount_value > 0
    ) {
      totalDiscount += discountResult.discount_value;
      // Attach money_amount for our line_discounts array
      line_discounts.push({
        id: discount.id,
        name: discount.name,
        money_amount: discountResult.discount_value,
        type: discount.type,
        created_at: discount.created_at,
        store_id: discount.store_id,
        updated_at: discount.updated_at,
        discount_amount: discount.discount_amount,
        discount_percent: discount.discount_percent,
        restricted_access: discount.restricted_access,
      });
    }
  }

  return {
    ...item,
    line_discounts,
    total_discount: totalDiscount,
  };
}
