import { isProductForSale, StoreProduct } from '@bofrak-backend/shared';
import {
  AspectRatio,
  Badge,
  Box,
  Button,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { IsAvailable } from './availability';
import { SelectChild } from './select-child';

import { colors } from '@bofrak-backend/shared';
import defaultProductImage from '../../assets/default-product.png';

interface Props {
  product: StoreProduct;
  cart_quantity?: number;
  addItem: (product: StoreProduct) => void;
}

export const ProductCard = (props: Props) => {
  const { product, addItem, cart_quantity } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  // Is available for sale at least one of the store product fractions is available for sale or the product is available for sale AND the inventory is greater than the smallest fraction
  const all_fractions_available_for_sale = product.store_product_fractions.some(
    (fraction) => {
      const { is_available_for_sale } = isProductForSale({
        fraction: fraction.fraction,
        is_sold_online: fraction.is_sold_online,
        is_available_for_sale: fraction.is_available_for_sale,
        inventory: fraction.inventory,
        is_available_in_store: fraction.is_available_in_store,
      });

      return is_available_for_sale;
    },
  );

  const { is_available_for_sale: is_product_available_for_sale } =
    isProductForSale({
      fraction: product.fraction,
      is_sold_online: product.is_sold_online,
      is_available_for_sale: product.is_available_for_sale,
      inventory: product.inventory,
      is_available_in_store: product.is_available_in_store,
    });

  const is_available_for_sale =
    all_fractions_available_for_sale || is_product_available_for_sale;

  if (!product) return null;

  return (
    <>
      <Stack
        position={'relative'}
        boxShadow={'2xl'}
        cursor={is_available_for_sale ? 'default' : 'not-allowed'}
        spacing={{ base: '1', md: '3' }}
        padding={'20px'}
        borderRadius={'2xl'}
        bgColor={'transparent'}>
        <IsAvailable
          cart_quantity={cart_quantity}
          is_available_for_sale={is_available_for_sale}
          position="absolute"
          top="-1"
          right="1"
          aria-label={`${product.name} is ${
            is_available_for_sale ? 'available' : 'not available'
          }`}
        />
        <Stack spacing={0}>
          <Text as="h1" fontWeight={'bold'}>
            {product.name}
          </Text>
          <Text color="fg.muted" fontSize={'xs'}>
            {product.product_fractions.length === 0
              ? 'No sub products'
              : product.product_fractions.length === 1
                ? '1 sub product'
                : `${product.product_fractions.length} sub products`}
          </Text>
        </Stack>
        <Box
          onClick={() => {
            if (!is_available_for_sale) return;
            if (product.is_parent) {
              onOpen();
            } else {
              addItem(product);
            }
          }}
          position="relative">
          <AspectRatio ratio={1 / 1}>
            <Image
              src={product.image}
              alt={product.name}
              draggable="false"
              fallback={
                <Image src={defaultProductImage} objectFit={'contain'} />
              }
            />
          </AspectRatio>
        </Box>
        <Stack>
          <VStack width={'full'}>
            <Badge
              variant="outline"
              textAlign={'center'}
              minW={'100px'}
              borderRadius={'full'}
              colorScheme={product.inventory > 0 ? 'green' : 'gray'}>
              {product.inventory.toFixed(2)} in stock
            </Badge>

            <Button
              width={'100px'}
              padding={'2'}
              isDisabled={!is_available_for_sale}
              onClick={() => (product.is_parent ? onOpen() : addItem(product))}
              bg={colors.primary}
              borderRadius={'full'}
              size="xs">
              Select
            </Button>
          </VStack>
        </Stack>
      </Stack>
      <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          position="absolute"
          top={{
            base: 'NA',
            md: '10%',
          }}
          right={{
            base: 'NA',
            md: '35%',
          }}>
          <ModalCloseButton />
          <SelectChild product={product} />
        </ModalContent>
      </Modal>
    </>
  );
};
