// PaymentForm.tsx
import {
  colors,
  formatCurrency,
  PaymentMethodType,
  ReceiptPayment,
} from '@bofrak-backend/shared';
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  NumberInput,
  NumberInputField,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useCart } from '../../hooks/use-cart';
import { paymentTypesAtom } from '../../recoil/atoms';

interface PaymentName {
  name: string;
  type: PaymentMethodType;
  id: string;
}

const PaymentForm = () => {
  const { cart, updatePayment, resetPayments, change } = useCart();
  const paymentTypes = useRecoilValue(paymentTypesAtom);
  const toast = useToast();

  const grossTotalMoney = Math.ceil(cart ? cart.gross_total_money : 0);

  const totalAllocated = Math.ceil(
    cart?.payments?.reduce(
      (acc, payment: ReceiptPayment) => acc + payment.money_amount,
      0,
    ) || 0,
  );

  const remaining = Math.ceil(grossTotalMoney - totalAllocated);

  // Step 1: Sort payment types to have "CASH" first
  const sortedPaymentTypes = [...paymentTypes].sort((a) =>
    a.payment_type === PaymentMethodType.CASH ? -1 : 0,
  );

  const paymentTypesNames: PaymentName[] = [
    ...sortedPaymentTypes.map((p) => ({
      name: p.name,
      type: p.payment_type,
      id: p.id,
    })),
    { name: 'Split', type: PaymentMethodType.OTHER, id: 'split' },
  ];

  // Step 2: Set default tab index to "CASH"
  const defaultTabIndex = paymentTypesNames.findIndex(
    (name) => name.type === PaymentMethodType.CASH,
  );

  const [selectedTabIndex, setSelectedTabIndex] = useState(defaultTabIndex);

  return (
    <Container maxW="container.md" h="100vh" overflowY="auto">
      <Stack spacing="3">
        <Stack
          spacing="4"
          direction={{ base: 'column', sm: 'row' }}
          justify="space-between">
          <VStack width="full">
            <Heading size="md">Payments</Heading>
            <HStack width="full" justify="space-between">
              <Text textStyle="lg" fontWeight="medium">
                Total Money
              </Text>
              <Text textStyle="lg" fontWeight="bold">
                {formatCurrency(grossTotalMoney)}
              </Text>
            </HStack>
            <HStack width="full" justify="space-between">
              <Text textStyle="lg" fontWeight="medium">
                Remaining
              </Text>
              <Text
                color={remaining === 0 ? 'green.500' : 'orange.500'}
                textStyle="lg"
                fontWeight="bold">
                {formatCurrency(remaining)}
              </Text>
            </HStack>
            <HStack width="full" justify="space-between">
              <Text textStyle="lg" fontWeight="medium">
                Allocated
              </Text>
              <Text
                color={
                  totalAllocated === grossTotalMoney ? 'green.500' : 'red.500'
                }
                textStyle="lg"
                fontWeight="bold">
                {formatCurrency(totalAllocated)}
              </Text>
            </HStack>
            {change && (
              <HStack width="full" justify="space-between">
                <Text textStyle="lg" fontWeight="medium">
                  Change
                </Text>
                <Text color={'red'} textStyle="lg" fontWeight="black">
                  {formatCurrency(change)}
                </Text>
              </HStack>
            )}
          </VStack>
        </Stack>

        <>
          <Divider />

          <Heading textAlign={'center'} size="xs">
            Choose Payment
          </Heading>

          <Divider />
          <Stack width={'full'} spacing="4">
            <Tabs
              isFitted
              width="full"
              color={colors.primary}
              size={'lg'}
              index={selectedTabIndex}
              onChange={(index) => setSelectedTabIndex(index)}>
              <TabList>
                {paymentTypesNames.map((name, index) => (
                  <Tab fontWeight={'semibold'} key={index + name.type}>
                    {name.name}
                  </Tab>
                ))}
              </TabList>
              <TabIndicator />
              <TabPanels>
                {sortedPaymentTypes.map((paymentType) => {
                  const existingPayment = cart?.payments?.find(
                    (p) => p.payment_type_id === paymentType.id,
                  );

                  return (
                    <TabPanel key={paymentType.id}>
                      <FormControl
                        padding={'2'}
                        key={paymentType.id}
                        id={`payment-${paymentType.id}`}>
                        <Stack
                          direction={{ base: 'column', md: 'row' }}
                          spacing={{ base: '1.5', md: '2' }}
                          justify="space-between">
                          <FormLabel variant="inline">
                            <Heading size="sm">{paymentType.name}</Heading>
                            {paymentType.payment_type ===
                              PaymentMethodType.LOYALTY_POINTS && (
                              <Text fontSize="sm" color="gray.500">
                                {`Available points: ${formatCurrency(cart?.customer?.total_points) ?? formatCurrency(0)}`}
                              </Text>
                            )}
                          </FormLabel>
                          <NumberInput
                            size="md"
                            maxW={{ md: '3xl' }}
                            isDisabled={remaining <= 0}
                            borderRadius={'full'}
                            defaultValue={existingPayment?.money_amount || ''}
                            onChange={(valueString) => {
                              if (!cart) {
                                toast({
                                  title: 'Error',
                                  description: 'Cart not found',
                                  status: 'error',
                                  duration: 5000,
                                  isClosable: true,
                                });
                                return;
                              }

                              if (!cart.customer) {
                                toast({
                                  title: 'Error',
                                  description: 'Customer not found',
                                  status: 'error',
                                  duration: 5000,
                                  isClosable: true,
                                });
                                return;
                              }

                              const amount = parseFloat(valueString) || 0;

                              if (
                                paymentType.payment_type ===
                                PaymentMethodType.LOYALTY_POINTS
                              ) {
                                // Make sure the customer has enough points, otherwise return a toast and do nothing

                                if (cart.customer.total_points < amount) {
                                  toast({
                                    title: 'Insufficient points',
                                    description: `You only have ${cart.customer.total_points} points`,
                                    status: 'error',
                                    duration: 5000,
                                    isClosable: true,
                                  });
                                  return;
                                }
                              }

                              const newPayment: ReceiptPayment = {
                                payment_type_id: paymentType.id,
                                money_amount: amount,
                                payment_type: paymentType.payment_type,
                                note: existingPayment?.note || '',
                                created_at:
                                  existingPayment?.created_at ||
                                  new Date().toISOString(),
                                updated_at: new Date().toISOString(),
                              };

                              updatePayment(newPayment);
                            }}>
                            <NumberInputField borderRadius={'lg'} />
                          </NumberInput>
                        </Stack>
                        <Input
                          mt="2"
                          borderRadius={'full'}
                          placeholder="Add a note (optional)"
                          value={existingPayment?.note || ''}
                          onChange={(e) => {
                            const note = e.target.value;
                            if (
                              existingPayment &&
                              existingPayment.money_amount > 0
                            ) {
                              const updatedPayment: ReceiptPayment = {
                                ...existingPayment,
                                note: note,
                                updated_at: new Date().toISOString(),
                              };
                              updatePayment(updatedPayment);
                            }
                          }}
                          isDisabled={
                            !existingPayment ||
                            existingPayment.money_amount <= 0
                          }
                        />
                      </FormControl>
                    </TabPanel>
                  );
                })}
                <TabPanel>
                  <VStack
                    spacing={'4'}
                    maxH="400px"
                    width={'full'}
                    overflowY="auto">
                    {sortedPaymentTypes.map((paymentType) => {
                      const existingPayment =
                        cart?.payments?.find(
                          (p) => p.payment_type_id === paymentType.id,
                        ) || null;

                      return (
                        <FormControl
                          boxShadow={'lg'}
                          padding={'2'}
                          key={paymentType.id}
                          id={`payment-${paymentType.id}`}>
                          <Stack
                            direction={{ base: 'column', md: 'row' }}
                            spacing={{ base: '1.5', md: '2' }}
                            justify="space-between">
                            <FormLabel variant="inline">
                              {paymentType.name}
                            </FormLabel>
                            <NumberInput
                              maxW={{ md: '3xl' }}
                              isDisabled={remaining <= 0}
                              borderRadius={'full'}
                              defaultValue={existingPayment?.money_amount || ''}
                              onChange={(valueString) => {
                                const amount = parseFloat(valueString) || 0;

                                const newPayment: ReceiptPayment = {
                                  payment_type_id: paymentType.id,
                                  money_amount: amount,
                                  payment_type: paymentType.payment_type,
                                  note: existingPayment?.note || '',
                                  created_at:
                                    existingPayment?.created_at ||
                                    new Date().toISOString(),
                                  updated_at: new Date().toISOString(),
                                };
                                updatePayment(newPayment);
                              }}>
                              <NumberInputField borderRadius={'lg'} />
                            </NumberInput>
                          </Stack>

                          <Input
                            mt="2"
                            borderRadius={'full'}
                            placeholder="Add a note (optional)"
                            value={existingPayment?.note || ''}
                            onChange={(e) => {
                              const note = e.target.value;
                              if (
                                existingPayment &&
                                existingPayment.money_amount > 0
                              ) {
                                const updatedPayment: ReceiptPayment = {
                                  ...existingPayment,
                                  note: note,
                                  updated_at: new Date().toISOString(),
                                };
                                updatePayment(updatedPayment);
                              }
                            }}
                            isDisabled={
                              !existingPayment ||
                              existingPayment.money_amount <= 0
                            }
                          />
                        </FormControl>
                      );
                    })}
                  </VStack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Stack>
          <Box
            position="sticky"
            bottom="30px"
            width="100%"
            maxW="container.md"
            px="4"
            py="2"
            bg="white"
            zIndex="10"
            display="flex"
            justifyContent="space-around">
            <Button
              borderRadius="full"
              onClick={() => {
                if (cart && cart.cart_id) {
                  resetPayments(cart.cart_id);
                }
              }}
              bg={colors.red}
              size="lg"
              fontFamily="heading"
              fontSize="x-large"
              fontWeight="bold">
              Reset
            </Button>
          </Box>
        </>
      </Stack>
    </Container>
  );
};

export default PaymentForm;
